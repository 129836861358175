export default [
  {
    path: "/users",
    name: "users",
    component: () => import("@/views/UserManagement/index.vue"),
    meta: {
      authReuire: true,
      role: ["Admin"],
      pageTitle: "Users",
      breadcrumb: [
        {
          text: "Users",
          active: true,
        },
      ],
    },
  },
];
