import { api } from "../index";
import notification from "@/ApiConstance/toast";

export default {
  async allAttendences(payload) {
    return await api.post("/getallattendences", payload);
  },

  async updateAttendenceSummary(payload) {
    return await api.post("/updateattendencesumary", payload).then(() => {
      notification.toast("SuccessFully Submited", "success");
      window.location.href = "/attendences";
    });
  },
};
