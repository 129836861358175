export default [
  {
    path: "/meetings",
    name: "meetings",
    component: () => import("@/views/MeetingManagement/meetings.vue"),
    meta: {
      authReuire: true,
      role: ["Admin"],
      pageTitle: "Meetings",
      breadcrumb: [
        {
          text: "Meetings",
          active: true,
        },
      ],
    },
  },

  {
    path: "/addmeeting",
    name: "addmeeting",
    component: () => import("@/views/MeetingManagement/AddMeeting.vue"),
    meta: {
      authReuire: true,
      role: ["Admin"],
      pageTitle: "Add Meeting",
      breadcrumb: [
        {
          text: "Add Meeting",
          active: true,
        },
      ],
    },
  },
];
