import { api } from "../index";
import store from "@/store";
import notification from "@/ApiConstance/toast";

export default {
  async register(payload) {
    return await api.post("/register", payload).then(() => {
      notification.toast("SuccessFully Registered", "success");
      window.location.href = "/login";
    });
  },
  async login(payload) {
    return await api.post("/login", payload).then((res) => {
      if (res.data.roles[0].name === "Admin") {
        notification.toast("SuccessFully Logged In", "success");
        const token = res.data.token;
        const role = res.data.roles[0].name;

        localStorage.setItem("aiotutoradminrole", role);
        localStorage.setItem("aiotutoradmintoken", token);
        localStorage.setItem("aiotutoradminuser", res.data.data.name);
        store.commit("SET_CURRENT_USER_NAME", res.data.data.name);
        store.commit("SET_LOGGED_ROLE", role);

        window.location.href = "/dashboard";
      } else {
        notification.toast("Access Denided", "error");
      }
    });
  },
  async profile() {
    return await api.get("/profile");
  },

  async logout() {
    return await api.get("/logout").then((res) => {
      notification.toast("SuccessFully Log Out", "success");
    });
  },

  async allUsers() {
    return await api.get("/getallusers");
  },

  async editUser(payload) {
    return await api.post("/updateuser", payload).then((res) => {
      notification.toast("SuccessFully Updated", "success");
      window.location.href = "/users";
    });
  },
};
