<template>
  <div>
    <b-card>
      <b-table responsive="sm" :items="items" :fields="fields">
        <template #cell(action)="data">
          <b-row>
            <b-col md="auto">
              <b-button variant="success" @click="openUpdateModal(data.item)">
                <feather-icon icon="EditIcon" class="mr-25"
              /></b-button>
            </b-col>
            <b-col md="auto">
              <b-button
                variant="danger"
                @click="deleteSubCategory(data.item.id)"
              >
                <feather-icon icon="DeleteIcon" class="mr-25"
              /></b-button>
            </b-col>
          </b-row>
        </template>
      </b-table>
    </b-card>
    <b-modal
      ref="updatemodal"
      v-model="show"
      hide-footer
      title="Update Sub Category"
    >
      <UpdateMeeting :meetingdata="selectedItem" />
    </b-modal>
  </div>
</template>

<script>
import UpdateMeeting from "@/views/MeetingManagement/Components/UpdateMeeting.vue";
import {
  BModal,
  BCard,
  BTable,
  BBadge,
  BButton,
  BCol,
  BRow,
  BCardText,
  BAvatar,
  BLink,
  BContainer,
} from "bootstrap-vue";
import meetingApi from "@/Api/Modules/meetings";
// import Echo from "laravel-echo";
import Pusher from "pusher-js";

export default {
  name: "MeetingTable",
  components: {
    BCard,
    UpdateMeeting,
    BModal,
    BButton,
    BCol,
    BBadge,
    BAvatar,
    BTable,
    BRow,
    BContainer,
    BCardText,
    BLink,
  },

  data() {
    return {
      show: false,
      selectedItem: {},
      data: {},
      fields: [
        {
          key: "meeting_id",
          label: "Meeting Link",
          sortable: true,
        },
        {
          key: "current_month",
          label: "Month Number",
          sortable: true,
        },
        {
          key: "link_status",
          label: "Link Status",
          sortable: true,
        },

        {
          key: "action",
          label: "Action",
          sortable: true,
        },
      ],
      items: [],
    };
  },
  async created() {
    // var pusher = new Pusher("09c8d5cbf40bbbb272f8", {
    //   cluster: "ap2",
    // });

    // var self = this;
    // var channel = pusher.subscribe("data-updates");
    // channel.bind("my-event", function (data) {
    //   self.items.push(data.data);
    // });

    await this.AllMeetings();
  },

  methods: {
    setCellPadding(value, key, item) {
      // Add a custom class to table cells based on your requirements
      return "custom-cell-padding";
    },
    async AllMeetings() {
      await this.$vs.loading({
        scale: 0.8,
      });
      const res = await meetingApi.allMeetings();
      this.items = res.data.data;
      this.$vs.loading.close();
    },

    async deleteSubCategory(id) {
      await this.$vs.loading({
        scale: 0.8,
      });
      await meetingApi
        .deleteMeeting(id)
        .then(() => {
          this.$vs.loading.close();
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
    openUpdateModal(item) {
      this.selectedItem = item;
      this.show = true;
    },
  },
};
</script>

<style>
.custom-cell-padding {
  height: 70px; /* Set your desired padding value */
}
</style>
