<template>
  <div>
    <div class="pt-2"></div>

    <div>
      <!-- users table -->
      <UserTable :users="users" />
    </div>
  </div>
</template>
<script>
import vSelect from "vue-select";
import UserTable from "@/views/UserManagement/Components/UserTable";
import usersApi from "@/Api/Modules/auth";
import { BCol, BRow, BButton, BModal } from "bootstrap-vue";
export default {
  data() {
    return {
      users: [],
    };
  },
  components: {
    vSelect,
    BModal,
    BButton,
    UserTable,
    BCol,
    BRow,
  },
  async created() {
    await this.Users();
  },

  methods: {
    // all users
    async Users() {
      await this.$vs.loading({
        scale: 0.8,
      });
      const res = await usersApi.allUsers();
      this.users = res.data.data;
      this.$vs.loading.close();
    },
  },
};
</script>
