<template>
  <div>
    <!-- table -->
    <b-card>
      <!-- if data not empty -->
      <div v-if="payments.length !== 0">
        <b-table responsive="sm" :items="payments" :fields="fields">
          <template #cell(amount)="data">
            {{ getPrice(data.value) }}
          </template>

          <template #cell(payment_date)="data">
            {{ momentFormat(data.value, "YYYY-MM-DD") }}
          </template>
        </b-table>
      </div>

      <!-- if data empty -->
      <div v-else class="text-center">
        <span class="font-weight-bold">No Result</span>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BModal,
  BCard,
  BTable,
  BBadge,
  BButton,
  BCol,
  BRow,
  BCardText,
  BAvatar,
  BLink,
  BContainer,
} from "bootstrap-vue";

export default {
  name: "PaymentTable",
  components: {
    BCard,
    BModal,
    BButton,
    BCol,
    BBadge,
    BAvatar,
    BTable,
    BRow,
    BContainer,
    BCardText,
    BLink,
  },
  data() {
    return {
      show: false,
      selectedItem: {},
      fields: [
        {
          key: "amount",
          label: "Paid Amount",
          sortable: true,
        },

        {
          key: "payment_date",
          label: "Payment Date",
          sortable: true,
        },
      ],
    };
  },
  props: {
    payments: Array,
  },
};
</script>

<style>
.custom-cell-padding {
  height: 70px; /* Set your desired padding value */
}
</style>
