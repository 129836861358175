export default [
  {
    path: "/attendences",
    name: "attendences",
    component: () => import("@/views/AttendenceManagement/index.vue"),
    meta: {
      authReuire: true,
      role: ["Admin"],
      pageTitle: "All Attendences",
      breadcrumb: [
        {
          text: "All Attendences",
          active: true,
        },
      ],
    },
  },
];
