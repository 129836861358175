import Vue from "vue";
import VueRouter from "vue-router";
import mainRoute from "./modules/home_route";
import paymentRoute from "./modules/payment_route";
import attendencesRoute from "./modules/attendence_route";
import userRoute from "./modules/users_route";
import MeetingRoute from "./modules/meetings_route";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    ...mainRoute,
    ...paymentRoute,
    ...attendencesRoute,
    ...userRoute,
    ...MeetingRoute,
  ],
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
