<template>
  <div>
    <!-- search button -->
    <div class="pt-3"></div>
    <div>
      <b-row>
        <b-col lg="4">
          <v-select
            id="paymentuser"
            v-model="selecteduser"
            :options="users"
            style="background-color: white"
            @input="loadPayments()"
            label="name"
          />
        </b-col>
      </b-row>
    </div>
    <div class="pt-3"></div>

    <!-- due balances -->
    <div class="paid_months" v-if="this.selecteduser.name !== null">
      <span class="title"
        >Active Status For {{ selecteduser.name }} :
        <span class="font-weight-bold" v-if="active_status !== null">{{
          active_status
        }}</span>

        <span v-else>N/A</span>
      </span>
    </div>

    <div class="pt-1"></div>

    <div class="paid_months" v-if="this.selecteduser.name !== null">
      <span class="title"
        >{{ selecteduser.name }} 's Paid Total:
        <span class="font-weight-bold">{{ getPrice(paid_total) }}</span>
      </span>
    </div>

    <div class="pt-1"></div>

    <div class="paid_months" v-if="this.selecteduser.name !== null">
      <span class="title"
        >{{ selecteduser.name }} 's Due Total:
        <span class="font-weight-bold">{{ getPrice(due_total) }}</span>
      </span>
    </div>

    <div class="pt-3"></div>

    <div v-if="this.selecteduser.name !== null">
      <!-- payment table -->
      <PaymentTable :payments="payments" />
    </div>
  </div>
</template>
<script>
import vSelect from "vue-select";
import PaymentTable from "@/views/PaymentManagement/Components/PaymentTable";
import paymentApi from "@/Api/Modules/payment";
import usersApi from "@/Api/Modules/auth";
import { BCol, BRow } from "bootstrap-vue";
export default {
  data() {
    return {
      payments: [],
      selecteduser: {
        name: "Select User",
      },
      users: [],
      paid_total: "",
      active_status: "",
      due_total: "",
    };
  },
  components: {
    vSelect,
    PaymentTable,
    BCol,
    BRow,
  },
  async created() {
    await this.initializeData();
  },

  methods: {
    setCellPadding(value, key, item) {
      // Add a custom class to table cells based on your requirements
      return "custom-cell-padding";
    },

    async initializeData() {
      await this.Users();

      // load payments
      if (this.users.length !== 0) {
        await this.AllPayments();
      }
    },
    // users
    async Users() {
      await this.$vs.loading({
        scale: 0.8,
      });

      const res = await usersApi.allUsers();
      this.users = res.data.data;
      if (this.users.length === 0) {
        this.selecteduser.name = "Select User";
      } else {
        this.selecteduser = this.users[0];
      }

      this.$vs.loading.close();
    },

    // load payments
    async loadPayments() {
      await this.AllPayments();
    },

    // all payments
    async AllPayments() {
      const payload = {
        user_id: this.selecteduser.id,
      };

      await this.$vs.loading({
        scale: 0.8,
      });

      const res = await paymentApi.allPayments(payload);

      this.payments = res.data.data.payments;
      this.paid_total = res.data.data.paid_total;
      this.active_status = res.data.data.active_status;
      this.due_total = res.data.data.due_total;

      this.$vs.loading.close();
    },
  },
};
</script>
