<template>
  <div>
    <!-- table -->
    <b-card>
      <!-- if data not empty -->
      <div v-if="users.length !== 0">
        <b-table responsive="sm" :items="users" :fields="fields">
          <template #cell(payment_type)="data">
            <span v-if="data.value !== null">{{ data.value }}</span>
            <span v-else>N/A</span>
          </template>

          <template #cell(payment_value)="data">
            <span v-if="data.value !== null">{{ data.value }}</span>
            <span v-else>N/A</span>
          </template>

          <template #cell(action)="data" >
            <b-row v-if="data.item.role_name !== 'Admin'">
              <b-col md="auto">
                <b-button variant="success" @click="openUpdateModal(data.item)">
                  <feather-icon icon="EditIcon" class="mr-25"
                /></b-button>
              </b-col>
            </b-row>
          </template>
        </b-table>
      </div>

      <!-- if data empty -->
      <div v-else class="text-center">
        <span class="font-weight-bold">No Result</span>
      </div>
    </b-card>

    <b-modal ref="updatemodal" hide-footer title="Update User">
      <UpdateUser :userdata="selectedItem" />
    </b-modal>
  </div>
</template>

<script>
import UpdateUser from "@/views/UserManagement/Components/UpdateUser";
import {
  BModal,
  BCard,
  BTable,
  BBadge,
  BButton,
  BCol,
  BRow,
  BCardText,
  BAvatar,
  BLink,
  BContainer,
} from "bootstrap-vue";

export default {
  name: "UserTable",
  components: {
    UpdateUser,
    BCard,
    BModal,
    BButton,
    BCol,
    BBadge,
    BAvatar,
    BTable,
    BRow,
    BContainer,
    BCardText,
    BLink,
  },
  data() {
    return {
      show: false,
      selectedItem: {},
      fields: [
        {
          key: "name",
          label: "Name",
          sortable: true,
        },
        {
          key: "email",
          label: "Email",
          sortable: true,
        },
        {
          key: "role_name",
          label: "Role Name",
          sortable: true,
        },
        {
          key: "payment_type",
          label: "Payment Type",
          sortable: true,
        },
        {
          key: "payment_value",
          label: "Payment Value",
          sortable: true,
        },
        {
          key: "action",
          label: "Action",
          sortable: true,
        },
      ],
    };
  },
  props: {
    users: Array,
  },

  methods: {
    openUpdateModal(data) {
      this.selectedItem = data;
      this.$refs.updatemodal.show();
    },
  },
};
</script>

<style>
.custom-cell-padding {
  height: 70px; /* Set your desired padding value */
}
</style>
