<template>
  <div>
    <MeetingsTable />
  </div>
</template>
<script>
import MeetingsTable from "@/views/MeetingManagement/Components/MeetingTable";
export default {
  components: {
    MeetingsTable,
  },
};
</script>
