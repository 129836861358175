export default [
  {
    title: "Dashboard",
    route: "dashboard",
    icon: "HomeIcon",
  },

  {
    title: "Users",
    route: "users",
    icon: "UserIcon",
  },

  {
    title: "Meetings",
    icon: "VideoIcon",
    children: [
      {
        title: "Add Meeting",
        route: "addmeeting",
        icon: "PlusSquareIcon",
      },
      {
        title: "Meetings",
        route: "meetings",
        icon: "VideoIcon",
      },
    ],
  },

  {
    title: "Payments",
    icon: "DollarSignIcon",
    children: [
      {
        title: "Add Payment",
        route: "addpayment",
        icon: "PlusIcon",
      },
      {
        title: "Payments",
        route: "allpayments",
        icon: "DollarSignIcon",
      },
    ],
  },

  {
    title: "Attendences",
    route: "attendences",
    icon: "UserIcon",
  },
];
