<template>
  <div>
    <!-- search button -->
    <div class="pt-3"></div>
    <div>
      <b-row>
        <b-col lg="6">
          <v-select
            id="paymentuser"
            v-model="selecteduser"
            :options="users"
            style="background-color: white"
            @input="loadAttendences()"
            label="name"
          />
        </b-col>

        <b-col lg="6">
          <b-button variant="primary" @click="$refs.openAttendence.show()">
            Set Today Attendence</b-button
          >
        </b-col>
      </b-row>
    </div>

    <div class="pt-2"></div>

    <div class="paid_months" v-if="this.selecteduser.name !== null">
      <span class="title"
        >Full Attendence Count:
        <span class="font-weight-bold">{{ fullattendences }}</span>
      </span>
    </div>

    <div class="pt-2"></div>
    <!-- due balances -->
    <div class="paid_months" v-if="this.selecteduser.name !== null">
      <span class="title"
        >Attendence Count For {{ selecteduser.name }} :
        <span class="font-weight-bold" v-if="attendencescount !== null">{{
          attendencescount
        }}</span>

        <span v-else>N/A</span>
      </span>
    </div>

    <div class="pt-2"></div>

    <div class="paid_months" v-if="this.selecteduser.name !== null">
      <span class="title"
        >{{ selecteduser.name }} 's Attendence Percentage:
        <span class="font-weight-bold">{{ getFloatValue(attendencespercentage) }}%</span>
      </span>
    </div>
    <div class="pt-2"></div>

    <div class="paid_months" v-if="this.selecteduser.name !== null">
      <span class="title"
        >Attendence accesible Status:
        <span class="font-weight-bold">{{ accesible_staus }}</span>
      </span>
    </div>

    <div class="pt-3"></div>

    <div v-if="this.selecteduser.name !== null">
      <!-- payment table -->
      <AttendencesTable :attendences="attendences" />
    </div>

    <b-modal ref="openAttendence" hide-footer title="Set Today Attendence">
      <div>
        <v-select
          id="attendencestatus"
          v-model="selectedstatus"
          :options="statuses"
          style="background-color: white"
          label="status"
        />
        <br />
        <b-button variant="primary" @click="submitAttendence()" block>
          Submit</b-button
        >
      </div>
    </b-modal>
  </div>
</template>
<script>
import vSelect from "vue-select";
import AttendencesTable from "@/views/AttendenceManagement/Components/AttendenceTable";
import attendencesApi from "@/Api/Modules/attendence";
import usersApi from "@/Api/Modules/auth";
import { BCol, BRow, BButton, BModal } from "bootstrap-vue";
export default {
  data() {
    return {
      attendencescount: "",
      attendencespercentage: "",
      fullattendences: "",
      accesible_staus: "",
      attendences: [],
      selecteduser: {
        name: "Select User",
      },
      users: [],
      statuses: [
        {
          status: "Enable",
        },
        {
          status: "Disable",
        },
      ],
      selectedstatus: {
        status: "Enable",
      },
    };
  },
  components: {
    vSelect,
    BModal,
    BButton,
    AttendencesTable,
    BCol,
    BRow,
  },
  async created() {
    await this.initializeData();
  },

  methods: {
    setCellPadding(value, key, item) {
      // Add a custom class to table cells based on your requirements
      return "custom-cell-padding";
    },

    async initializeData() {
      await this.Users();

      // // load payments
      if (this.users.length !== 0) {
        await this.AllAttendences();
      }
    },
    // users
    async Users() {
      await this.$vs.loading({
        scale: 0.8,
      });

      const res = await usersApi.allUsers();
      this.users = res.data.data;
      if (this.users.length === 0) {
        this.selecteduser.name = "Select User";
      } else {
        this.selecteduser = this.users[0];
      }

      this.$vs.loading.close();
    },

    // load attendences
    async loadAttendences() {
      await this.AllAttendences();
    },

    // all attendences
    async AllAttendences() {
      const payload = {
        user_id: this.selecteduser.id,
      };

      await this.$vs.loading({
        scale: 0.8,
      });

      const res = await attendencesApi.allAttendences(payload);

      this.attendences = res.data.data.user_attendeces;
      this.attendencescount = res.data.data.user_attended_count;
      this.fullattendences = res.data.data.full_attended_count;
      this.attendencespercentage = res.data.data.user_attended_percentage;
      this.accesible_staus = res.data.data.accesible_staus;

      this.$vs.loading.close();
    },

    // submit attendence
    async submitAttendence() {
      const payload = {
        status: this.selectedstatus.status,
      };

      await this.$vs.loading({
        scale: 0.8,
      });

      await attendencesApi
        .updateAttendenceSummary(payload)
        .then((res) => {
          this.attendences = res.data.data;
          this.$vs.loading.close();
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
  },
};
</script>
