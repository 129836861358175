<template>
  <div>
    <div>
      <!-- Register v1 -->
      <b-card class="mb-0" title="">
        <!-- form -->
        <validation-observer ref="updateUser" #default="{ invalid }">
          <b-form @submit.prevent>
            <!-- name -->
            <b-col cols="12">
              <b-form-group label="User Name" label-for="Name">
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  vid="Name"
                  rules="required"
                >
                  <b-form-input
                    id="register-meetingid"
                    v-model="form.name"
                    name="register-meetingid"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Enter Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- email -->
            <b-col cols="12">
              <b-form-group label="User Email" label-for="Email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="Email"
                  rules="required"
                >
                  <b-form-input
                    id="register-meetingid"
                    v-model="form.email"
                    name="register-meetingid"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Enter Email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Payment Type -->
            <b-col cols="12">
              <b-form-group label="User Payment Type" label-for="Payment Type">
                <validation-provider
                  #default="{ errors }"
                  name="Payment Type"
                  vid="Payment Type"
                >
                  <b-form-input
                    id="register-meetingid"
                    v-model="form.payment_type"
                    name="register-meetingid"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Enter Payment Type"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- payment value-->
            <b-col cols="12">
              <b-form-group
                label="User Payment Value"
                label-for="Payment Value"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Payment Value"
                  vid="Payment Value"
                >
                  <b-form-input
                    id="register-meetingid"
                    v-model="form.payment_value"
                    name="register-meetingid"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Enter Payment Value"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-button
              variant="primary"
              block
              type="submit"
              @click="updateUser()"
              :disabled="invalid"
            >
              Update
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>
      <!-- /Register v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import userApi from "@/Api/Modules/auth";
import {
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BForm,
  BButton,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BImg,
  BInputGroupAppend,
} from "bootstrap-vue";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
} from "@validations";
export default {
  name: "Register",
  components: {
    vSelect,
    BCard,

    BCardTitle,
    BImg,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,

    // validations
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      form: {},

      // validation rules
      required,
      email,
      confirmed,
      url,
      between,
      alpha,
      integer,
      password,
      min,
      digits,
      alphaDash,
      length,
    };
  },

  props: {
    userdata: Object,
  },

  created() {
    this.initiateData();
  },

  methods: {
    // initiate data

    initiateData() {
      this.form = this.userdata;
    },
    // update

    async updateUser() {
      if (await this.$refs.updateUser.validate()) {
        await this.$vs.loading({
          scale: 0.8,
        });

        await userApi
          .editUser(this.form)
          .then(() => {
            this.$vs.loading.close();
          })
          .catch(() => {
            this.$vs.loading.close();
          });
      }
    },
  },
};
</script>
